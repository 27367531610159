import $ from "jquery";
import "./common-string.js";
import "./font.js";
import {parseBoolean} from "./common-type.js";
import Tooltip from "bootstrap/js/dist/tooltip";

/**
 * Fetches the current CSRF token from the API and updates the meta tag in the document head,
 * as well as all input fields with name="_token" to ensure the CSRF token remains valid
 * during the user's session.
 */
function updateCsrfToken() {
    fetch("/api/csrf-token", {
        method: "GET",
    })
        .then(response => response.json())
        .then(data => {
            /* The new CSRF token retrieved from the API response. */
            const csrfToken = data["csrf-token"];

            /* Update the meta tag with the new CSRF token. */
            const metaTag = document.querySelector("meta[name=\"csrf-token\"]");
            if (metaTag) {
                metaTag.setAttribute("content", csrfToken);
            } else {
                const newMetaTag = document.createElement("meta");
                newMetaTag.name = "csrf-token";
                newMetaTag.content = csrfToken;
                document.head.appendChild(newMetaTag);
            }

            /* Update all input fields with name="_token" to the new CSRF token. */
            const tokenInputs = document.querySelectorAll("input[name=\"_token\"]");
            tokenInputs.forEach(input => {
                input.value = csrfToken;
            });
        })
        .catch(error => console.error("Failed to update CSRF token:", error));
}

/**
 * Initializes the CSRF token update mechanism.
 * Calls the updateCsrfToken function immediately and sets an interval to refresh
 * the CSRF token every 5 minutes (300,000 milliseconds).
 */
function initCsrfTokenRefresh() {
    updateCsrfToken();
    setInterval(updateCsrfToken, 5 * 60 * 1000);
}

/* Initialize CSRF token refresh process on page load. */
initCsrfTokenRefresh();

/* Add a tooltip to anything with a title */
try {
    let toolTipElements = document.querySelectorAll("[title]");
    toolTipElements.forEach((element) => {
        new Tooltip(element);
    });
} catch (error) {
    /* Do nothing */
}

/* Activate any navigation link associated with the current page URL */
$(".nav-link").removeClass("active").removeAttr("aria-current");
$("a.nav-link[href=\"" + location.pathname + "\"]").addClass("active").attr("aria-current", "page");

if (parseBoolean(sessionStorage.getItem("isMedicare"))) {
    updateAgentPhoneNumber(decodeHtmlEntities(sessionStorage.getItem("agentPhoneNumberOver65")), sessionStorage.getItem("agentPhoneNumberOver65Uri"));
} else {
    updateAgentPhoneNumber(decodeHtmlEntities(sessionStorage.getItem("agentPhoneNumberUnder65")), sessionStorage.getItem("agentPhoneNumberUnder65Uri"));
}

function decodeHtmlEntities(encodedStr) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(encodedStr, "text/html");
    return doc.documentElement.textContent;
}

$("a").filter(function () {
    return this.hostname && this.hostname !== location.hostname;
})
    .append("&nbsp;<sup class=\"bi bi-box-arrow-up-right\"></sup>")
    .attr("target", "_blank")
    .attr("rel", "noopener");

$("[target=_blank]").attr("aria-label", "opens in new window");

/* Add event for scrolling events. */
$(window).on("scroll", function () {
    if ($(this).scrollTop() < 150) {
        $("body").removeClass("scrolled");
    } else {
        $("body").addClass("scrolled");
    }
});

$(window).on("resize", function () {
    $("#header-shim").css("min-height", $("header").height() + "px");
});
window.dispatchEvent(new Event("resize"));

/* Adds a jQuery selector for data attributes.
 * See https://stackoverflow.com/questions/9499998/jquery-select-all-elements-that-have-jquery-data */
$.extend($.expr[":"], {
    data: $.expr.createPseudo ?
        $.expr.createPseudo(function (dataName) {
            return function (elem) {
                return !!$(elem).data(dataName);
            };
        }) : function (elem, i, match) {
            return !!$(elem).data(match[3]);
        },
});

/**
 * Scroll to the first element with the specified selector.
 *
 * @param {string} selector the selector to scroll to
 */
export function scrollTo(selector) {
    const element = $(selector);
    if (element.length) {
        element.get(0).scrollIntoView({behavior: "smooth"});
    }
}
